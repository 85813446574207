import React from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const KEY_ARRAY= ['StartTime','EndTime', 'Caption', 'Save'];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 880,
  },
});

export default function CaptionTable({ data, onTextFieldChange, onSave }) {
   const classes = useStyles();

  if(!Array.isArray(data) || !data.length || !data[0]) {
    return (null);
  }
  return (
    <TableContainer className={classes.container}>
     <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {KEY_ARRAY.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ start, end, caption }, index) => (
          <TableRow key={index}>
            <TableCell>{start}</TableCell>
            <TableCell>{end}</TableCell>
            <TableCell>
              <TextField
                variant="outlined"
                id={`${index}`}
                type="text"
                fullWidth={true}
                onChange={onTextFieldChange}
                defaultValue={caption} />
            </TableCell>
            <TableCell>
              <Button
                id={`${index}`}
                onClick={onSave}
                variant={"outlined"}
                color="primary">
                  <span id={`${index}`}>Save</span>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
}
