import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, LinearProgress} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Redirect } from "react-router-dom";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
// components
import Widget from "../../components/Widget/Widget";
import BigStat from "./components/BigStat/BigStat";
import Dot from "../../components/Sidebar/components/Dot";
import { Typography } from "../../components/Wrappers";
import ApexHeatmap from "../charts/components/ApexHeatmap";
import ApexLineChart from "../charts/components/ApexLineChart";

import {
  API_KEY
} from '../../utils/constants';

// API
import { formatVideosURL } from '../../utils/apis';

// Formatter
import {
  formatRequestHeader,
  formatLastWeekData,
  formatLastMonthData,
  formatOverallData,
  formatTotalCreatedDataByMonth,
  formatTotalSucceedDataByMonth,
  formatTotalErrorDataByMonth
} from '../../utils/formatter';


import useStyles from "./styles";

const PieChartData = [
  { name: "Success", value: 400, color: "primary" },
  { name: "Pending", value: 300, color: "secondary" },
  { name: "Failed", value: 100, color: "warning" },
];


function Dashboard(props) {
  const { accessTokenState } = props;
  const classes = useStyles();
  var theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [tasks, setTasks] = useState([]);

  const [totalCreatedLastWeek, setTotalCreatedLastWeek] = useState(0);
  const [totalSucceedLastWeek, setTotalSucceedLastWeek] = useState(0);
  const [totalFailedLastWeek, setTotalFailedLastWeek] = useState(0);
  const [successRateLastWeek, setSuccessRateLastWeek] = useState('100%');

  const [totalCreatedLastMonth, setTotalCreatedLastMonth] = useState(0);
  const [totalSucceedLastMonth, setTotalSucceedLastMonth] = useState(0);
  const [totalFailedLastMonth, setTotalFailedLastMonth] = useState(0);
  const [successRateLastMonth, setSuccessRateLastMonth] = useState('100');

  const [totalCreated, setTotalCreated] = useState(0);
  const [totalSucceed, setTotalSucceed] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);

  const [totalCreatedByMonth, setTotalCreatedByMonth] = useState([]);
  const [totalSucceedByMonth, setTotalSucceedByMonth] = useState([]);
  const [totalErroredByMonth, setTotalErroredByMonth] = useState([]);


  useEffect(() => {
    accessTokenState && axios.get(formatVideosURL(), {
      headers: formatRequestHeader(API_KEY, accessTokenState)
    }).then(resp => {
      // Use for tracking the mounted status
      const newTasks = resp['data']['videos'];
      // console.log('get result back')
      // console.log(newTasks);
      setTasks(newTasks);

      const lastWeekData = formatLastWeekData(newTasks);
      setTotalCreatedLastWeek(lastWeekData['TotalCreated']);
      setTotalSucceedLastWeek(lastWeekData['TotalSucceed']);
      setTotalFailedLastWeek(lastWeekData['TotalFailed']);
      setSuccessRateLastWeek(lastWeekData['SuccessRate']);

      const lastMonthData = formatLastMonthData(newTasks);
      setTotalCreatedLastMonth(lastMonthData['TotalCreated']);
      setTotalSucceedLastMonth(lastMonthData['TotalSucceed']);
      setTotalFailedLastMonth(lastMonthData['TotalFailed']);
      setSuccessRateLastMonth(lastMonthData['SuccessRate']);

      const overallData = formatOverallData(newTasks);
      setTotalCreated(overallData['TotalCreated']);
      setTotalSucceed(overallData['TotalSucceed']);
      setTotalFailed(overallData['TotalFailed']);

      const totalCreatedByMonth = formatTotalCreatedDataByMonth(newTasks);
      setTotalCreatedByMonth(totalCreatedByMonth);
      const totalSucceedByMonth = formatTotalSucceedDataByMonth(newTasks);
      setTotalSucceedByMonth(totalSucceedByMonth);
      const totalErroredByMonth = formatTotalErrorDataByMonth(newTasks);
      setTotalErroredByMonth(totalErroredByMonth);

      setIsPageLoading(false);
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
      setRedirect(true);
    });
  },[accessTokenState]);

  if(redirect) {
    return <Redirect to='/error500'/>;
  }

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget
            title="7 Days Created"
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {totalCreatedLastWeek}
              </Typography>
                </Grid>
                <Grid item xs={6}>
              <LineChart
                width={100}
                height={30}
                data={[
                  { value: 10 },
                  { value: 15 },
                  { value: 10 },
                  { value: 17 },
                  { value: 18 },
                ]}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Total Succeed
                </Typography>
                <Typography size="md">{totalSucceedLastWeek}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Total Failed
                </Typography>
                <Typography size="md">{totalFailedLastWeek}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Success Rate
                </Typography>
                <Typography size="md">{successRateLastWeek}%</Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <BigStat
            totalCreatedLastMonth={totalCreatedLastMonth}
            totalSucceedLastMonth={totalSucceedLastMonth}
            totalFailedLastMonth={totalFailedLastMonth}
            successRateLastMonth={successRateLastMonth}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget title="Task Status" upperTitle disableWidgetMenu className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  <div key='primary' className={classes.legendItemContainer}>
                      <Dot color='primary' />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;Total Created&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{totalCreated}
                      </Typography>
                    </div>
                    <div key='secondary' className={classes.legendItemContainer}>
                      <Dot color='secondary' />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;Total Succeed&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{totalSucceed}
                      </Typography>
                    </div>
                    <div key='warning' className={classes.legendItemContainer}>
                      <Dot color='warning' />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;Total Failed&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{totalFailed}
                      </Typography>
                    </div>
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Widget title="Monthly Data" upperTitle noBodyPadding disableWidgetMenu>
            <ApexLineChart
              totalCreatedByMonth={totalCreatedByMonth}
              totalSucceedByMonth={totalSucceedByMonth}
              totalErroredByMonth={totalErroredByMonth}
              />
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Weekly Data" upperTitle noBodyPadding disableWidgetMenu>
            <ApexHeatmap tasks={tasks}/>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(Dashboard);