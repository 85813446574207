import React from "react";
import { Grid } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { BarChart, Bar } from "recharts";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Widget from "../../../../components/Widget";
import { Typography } from "../../../../components/Wrappers";

export default function BigStat(props) {
  var {
    totalCreatedLastMonth,
    totalSucceedLastMonth,
    totalFailedLastMonth,
    successRateLastMonth
  } = props;
  var classes = useStyles();
  var theme = useTheme();

  return (
    <Widget
      header={
        <div className={classes.title}>
          <Typography variant="h5">30 Days Created</Typography>
        </div>
      }
      upperTitle
      bodyClass={classes.bodyWidgetOverflow}
    >
      <div className={classes.totalValueContainer}>
        <div className={classes.totalValue}>
          <Typography size="xxl" color="text" colorBrightness="secondary">
            {totalCreatedLastMonth}
          </Typography>
        </div>
        <BarChart width={150} height={70} data={getRandomData()}>
          <Bar
            dataKey="value"
            fill={theme.palette.warning.main}
            radius={10}
            barSize={10}
          />
        </BarChart>
      </div>
      <div className={classes.bottomStatsContainer}>
        <div className={classnames(classes.statCell, classes.borderRight)}>
          <Grid container alignItems="center">
            <Typography variant="h6">{totalSucceedLastMonth}</Typography>
            <ArrowForwardIcon
              className={classnames(classes.profitArrow)}
            />
          </Grid>
          <Typography size="sm" color="text" colorBrightness="secondary">
            Total Succeed
          </Typography>
        </div>
        <div className={classes.statCell}>
          <Grid container alignItems="center">
            <Typography variant="h6">{successRateLastMonth}%</Typography>
            <ArrowForwardIcon
              className={classnames(classes.profitArrow)}
            />
          </Grid>
          <Typography size="sm" color="text" colorBrightness="secondary">
            Success Rate
          </Typography>
        </div>
        <div className={classnames(classes.statCell, classes.borderRight)}>
          <Grid container alignItems="center">
            <Typography variant="h6">
              {totalFailedLastMonth}
            </Typography>
            <ArrowForwardIcon
              className={classnames(classes.profitArrow)}
            />
          </Grid>
          <Typography size="sm" color="text" colorBrightness="secondary">
            Total Failed
          </Typography>
        </div>
      </div>
    </Widget>
  );
}

// #######################################################################

function getRandomData() {
  return Array(7)
    .fill()
    .map(() => ({ value: Math.floor(Math.random() * 10) + 1 }));
}
