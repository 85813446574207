import React, { Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Layout from "./Layout";
import ProtectedRoute from '../components/ProtectedRoute';

// pages
import IntroPage from '../pages/introPage';
import DashboardPage from '../pages/Dashboard';
import UploadVideoPage from '../pages/UploadVideoPage';
import VocabularyPage from '../pages/VocabularyPage';
import TweaksPage from '../pages/TweaksPage';
import VideosPage from "../pages/VideosPage";
import VideoPage from "../pages/VideoPage";
import ProfilePage from "../pages/ProfilePage";
import SupportPage from "../pages/SupportPage";
import UpgradePage from "../pages/UpgradePage";
import RecentPage from "../pages/RecentPage";
import StarredPage from "../pages/StarredPage";
import UsagePage from "../pages/UsagePage";
import Error from "../pages/error";

// Routes
import {
  INTRO,
  DASHBOARD,
  VIDEOS,
  VIDEO,
  UPLOAD,
  VOCABULARY,
  TWEAK,
  UPGRADE,
  SUPPORT,
  PROFILE,
  RECENT,
  STARRED,
  USAGE,
  ERROR_500,
  CATCH_ALL
} from '../utils/constants';


const HeaderSideBarElement = (WrappedComponent) => (props) => {
  return (
    <Layout>
      <WrappedComponent {...props}/>
    </Layout>
  )
}

export default function App() {
  return (
    <AnimatePresence>
      <Suspense>
        <Switch>
          <ProtectedRoute exact path={INTRO} component={HeaderSideBarElement(IntroPage)} />
          <ProtectedRoute exact path={DASHBOARD} component={HeaderSideBarElement(DashboardPage)} />
          <ProtectedRoute exact path={VIDEOS} component={HeaderSideBarElement(VideosPage)} />
          <ProtectedRoute exact path={UPLOAD} component={HeaderSideBarElement(UploadVideoPage)} />
          <ProtectedRoute exact path={VOCABULARY} component={HeaderSideBarElement(VocabularyPage)} />
          <ProtectedRoute exact path={TWEAK} component={HeaderSideBarElement(TweaksPage)} />
          <ProtectedRoute exact path={VIDEO} component={HeaderSideBarElement(VideoPage)} />

          <ProtectedRoute exact path={PROFILE} component={HeaderSideBarElement(ProfilePage)} />
          <ProtectedRoute exact path={SUPPORT} component={HeaderSideBarElement(SupportPage)} />
          <ProtectedRoute exact path={UPGRADE} component={HeaderSideBarElement(UpgradePage)} />
          <ProtectedRoute exact path={RECENT} component={HeaderSideBarElement(RecentPage)} />
          <ProtectedRoute exact path={STARRED} component={HeaderSideBarElement(StarredPage)} />
          <ProtectedRoute exact path={USAGE} component={HeaderSideBarElement(UsagePage)} />

          <Route path={ERROR_500} component={Error} />
          <Route path={CATCH_ALL} component={Error} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
}
