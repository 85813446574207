import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { Box, Grid, LinearProgress, Paper} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Redirect } from "react-router-dom";
// components
import Widget from "../../components/Widget/Widget";
import Table from "../../components/Table/Table";
import { Typography } from "../../components/Wrappers/Wrappers";

// API
import {
  formatVideosURL,
  formatVideoURL
} from '../../utils/apis';
import {
  formatRequestHeader
} from '../../utils/formatter';
import { API_KEY } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

function VideosPage(props) {
  const { accessTokenState } = props;
  const classes = useStyles();
  const [videos, setVideos] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);


  const onStarClick = async (id, isToStar) => {
    startTask(id, isToStar);

    await axios.patch(formatVideoURL(id),
      {
        'isStarred': isToStar,
      },
      {
        headers: formatRequestHeader(null, accessTokenState)
      }
      ).then(resp => {
        //console.log(resp);
    }).catch(error => {
        console.error(error);
        startTask(id, !isToStar);
    });
  }

  const startTask = (id, isToStar) => {
    const deepcopy = JSON.parse(JSON.stringify(videos)); ;
    deepcopy.forEach(item => {
      if(item['videoId'] === id) {
        item['isStarred'] = isToStar;
      }
    })
    setVideos(deepcopy);
  }

  useEffect(() => {
    accessTokenState && axios.get(formatVideosURL(), {
      headers: formatRequestHeader(API_KEY,accessTokenState)
    }).then(resp => {
      // Use for tracking the mounted status
      setVideos(resp['data']['videos']);
      setIsPageLoading(false);
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
      setRedirect(true);
    });
  },[accessTokenState]);

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={4}>
     {
      videos.length !== 0 ?
          <Grid item xs={12}>
              <Table onStarClick={onStarClick} data={videos} />
          </Grid>
        :
        <Grid container>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={10} md={6}>
              <Box m={5}>
                <Widget disableWidgetMenu>
                  <div className={classes.dashedBorder}>
                    <Typography className={classes.text} size="xxl">
                      You do not have any videos yet. Please upload some videos.
                    </Typography>
                  </div>
                </Widget>
              </Box>
            </Grid>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid>
     }
     </Grid>
     </Paper>
    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(VideosPage);