import React from "react";
import { connect, useDispatch } from 'react-redux';
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  setUser,
  setIsAuth,
  setError,
  setAccesstoken,
  fetchAccesstoken
} from '../../reducerCollection/AuthOptions';
import { useAuth0 } from "@auth0/auth0-react";

function Layout(props) {
  var classes = useStyles();
  const { sidebarOpened } = props;
  const dispatch = useDispatch();

  const {
    isAuthState
  } = props;

  const {
    isAuthenticated,
    user
  } = useAuth0();

  // Update auth in state
  if(!isAuthenticated) {
    // Not auth, clean up auth state
    props.setIsAuth(false);
    props.setAccesstoken(null);
    props.setError(null);
    props.setUser(null);
  } else if(isAuthenticated && !isAuthState) {
    // Fetch access token from auth0
    dispatch(fetchAccesstoken());
    // First time auth
    props.setIsAuth(true);
    props.setUser(user);
  }

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: sidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
              {props.children}
            </div>
        </>
    </div>
  );
}

const mapStateToProps = state => ({
  sidebarOpened: state.ThemeOptions.sidebarOpened,
  isAuthState: state.AuthOptions.isAuth,
  errorState: state.AuthOptions.error,
  userState: state.AuthOptions.user,
  accessTokenState: state.AuthOptions.accessToken,
});

const mapDispatchToProps = dispatch => ({
  setUser: (user)  => dispatch(setUser(user)),
  setIsAuth: (isAuth)  => dispatch(setIsAuth(isAuth)),
  setError: (error)  => dispatch(setError(error)),
  setAccesstoken: (accessToken)  => dispatch(setAccesstoken(accessToken))
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
