import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Redirect } from "react-router-dom";
// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

// API
import { formatTweaksURL } from '../../utils/apis';
import { formatRequestHeader } from '../../utils/formatter';
import { API_KEY } from '../../utils/constants';


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

function TweaksPage(props) {
  const { accessTokenState } = props;
  const classes = useStyles();
  const [tweakList, setTweakList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const handleInfoDialogOpen = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  //input string array, output, a string with line break
  const parseArray = (stringList) => {
    let s = "";
    stringList.forEach(element => {
      s = s.concat(element);
      s = s.concat("\n");
    });
    return s;
  }

  const onTextFieldChange = (event) => {
    //console.log('onTextFieldChange');
    //console.log(event.target.value);
    const wordsList = event.target.value.trim().split(/\r?\n/);
    //console.log(wordsList);
    setTweakList(wordsList);
  }

  const onSubmit = () => {
    setIsPageLoading(true);
    axios.put(
      formatTweaksURL(),
      { 'tweaks':  tweakList},
      {
        headers: formatRequestHeader(API_KEY,accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        setIsPageLoading(false);
        handleInfoDialogOpen();
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
      setRedirect(true);
    });
  }

  useEffect(() => {
    accessTokenState && axios.get(
      formatTweaksURL(),
      {
        headers: formatRequestHeader(API_KEY,accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        // Use for tracking the mounted status
        const tweakList = resp['data']['tweaks'];
        // console.log(tweakList);
        setTweakList(tweakList);
        setIsPageLoading(false);
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
      setRedirect(true);
    });
  },[accessTokenState]);


  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Dialog
        open={infoDialogOpen}
        onClose={handleInfoDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, please check back later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
    </Dialog>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid container>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <Widget disableWidgetMenu>
                <div className={classes.dashedBorder}>
                  <Typography className={classes.text} size="md">
                    The following tweaks are made to captions as they are processed, add or edit tweaks below to fix common transcription or case issues. The required format is one peroperty per line, for example: C.S=CS note that the case of the left hand expression is ignored but the case of the right hand expression is preserved.
                  </Typography>
                  <Typography className={classes.text} size="md">
                      Example:{"\n"}
                      C.S=CS{"\n"}
                      A.I=AI{"\n"}
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <TextField
                variant="outlined"
                id="text"
                type="text"
                multiline={true}
                rowsMax="20"
                rows="20"
                fullWidth={true}
                onChange={onTextFieldChange}
                defaultValue={parseArray(tweakList)} />
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

      <Grid container spacing={2}>
          <Grid item xs={2} md={5}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={8} md={2}>
            <Box m={5}>
              <Button
              fullWidth={true}
              size='large'
              color="secondary"
              variant="contained"
              onClick={onSubmit}
              >
                  Submit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2} md={5}>
            <Paper></Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(TweaksPage);