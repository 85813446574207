const API_DOMAIN = 'https://foro16r1h8.execute-api.us-east-1.amazonaws.com/prod';


// vocabulary
export const formatVocabularyURL = () => {
  return `${API_DOMAIN}/vocabulary`;
}

// videos
export const formatVideosURL = () => {
  return `${API_DOMAIN}/videos`;
}

// video
export const formatVideoURL = (videoId) => {
  return `${API_DOMAIN}/video/${videoId}`;
}

// videostatus
export const formatVideoStatusURL = (videoId) => {
  return `${API_DOMAIN}/videostatus/${videoId}`;
}

// videoname
export const formatVideoNameURL = (videoId) => {
  return `${API_DOMAIN}/videoname/${videoId}`;
}

// videodescription
export const formatVideoScriptionURL = (videoId) => {
  return `${API_DOMAIN}/videodescription/${videoId}`;
}

// tweaks
export const formatTweaksURL = () => {
  return `${API_DOMAIN}/tweaks`;
}

// caption
export const formatPutCaptionURL = (videoId) => {
  return `${API_DOMAIN}/caption/${videoId}`;
}

export const formatGetCaptionsVTTURL = (videoId) => {
  return `${API_DOMAIN}/caption/${videoId}?format=webvtt`;
}

export const formatGetCaptionsSRTURL = (videoId) => {
  return `${API_DOMAIN}/caption/${videoId}?format=srt`;
}

// upload
export const formatUploadURL = (uploadFileName) => {
  return `${API_DOMAIN}/upload/${uploadFileName}`;
}

export const formatGetUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatCreateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateSupportURL = () => {
  return `${API_DOMAIN}/support`;
}
