import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { AUTH0_AUDIENCE } from '../utils/constants';

// API
import {
  formatGetUserURL,
  formatCreateUserURL
} from '../utils/apis';

import { formatRequestHeader } from '../utils/formatter';

export const SET_USER = 'AUTH_OPTIONS/SET_USER';
export const SET_USER_FIRSTNAME = 'AUTH_OPTIONS/SET_USER_FIRSTNAME';
export const SET_USER_LASTNAME = 'AUTH_OPTIONS/SET_USER_LASTNAME';
export const SET_USER_CONTACT_NUMBER = 'AUTH_OPTIONS/SET_USER_CONTACT_NUMBER';
export const SET_USER_PLAN = 'AUTH_OPTIONS/SET_USER_PLAN';
export const SET_USER_SPACE_LIMIT = 'AUTH_OPTIONS/SET_USER_SPACE_LIMIT';
export const SET_USER_TASK_LIMIT = 'AUTH_OPTIONS/SET_USER_TASK_LIMIT';

export const SET_USER_ADDRESS = 'AUTH_OPTIONS/SET_USER_ADDRESS';
export const SET_USER_ROLE = 'AUTH_OPTIONS/SET_USER_ROLE';
export const SET_ISAUTH = 'AUTH_OPTIONS/SET_ISAUTH';
export const SET_ERROR = 'AUTH_OPTIONS/SET_ERROR';
export const SET_ACCESSTOKEN = 'AUTH_OPTIONS/SET_ACCESSTOKEN';
export const FETCH_ACCESSTOKEN = 'AUTH_OPTIONS/FETCH_ACCESSTOKEN';

export const fetchAccesstoken = () => dispatch => {
  const { getAccessTokenSilently } = useAuth0();
  getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scope: "read:current_user",
      })
  .then(response => {
    dispatch({
      type: SET_ACCESSTOKEN,
      accessToken: response
    });
  })
  .catch(error => {
    console.error('getAccessTokenSilently error');
      throw (error);
  });
};

/**
 * Fetch user first, if 204
 * Create one
 */
export const fetchCreateOrGetUser = () => async (dispatch, getState) => {
  const authToken = getState().AuthOptions.accessToken;
  if(!authToken) {
    return Promise.resolve();
  }

  const isUserFound = await axios.get( formatGetUserURL(), { headers: formatRequestHeader(null,authToken) }
  ).then(resp => {
    //console.log(resp);
    //console.log('get something back');
    if(resp['status'] === 204) {
      return false;
    }
    dispatch(setUserFirstname(resp['data']['item']['firstname']));
    dispatch(setUserLastname(resp['data']['item']['lastname']));
    dispatch(setUserContactnumber(resp['data']['item']['contactnumber']));
    dispatch(setUserAddress(resp['data']['item']['address']));
    dispatch(setUserRole(resp['data']['item']['role']));
    dispatch(setUserPlan(resp['data']['item']['plan']));
    dispatch(setUserTaskLimit(resp['data']['item']['plantasklimit']));
    dispatch(setUserSpaceLimit(resp['data']['item']['planspacelimit']));
    return true;
  }).catch(error => {
    if (error.response && error.response.status === 404) {
      return false;
    } else {
      console.error('fetch user error');
      throw (error);
    }
  });

  // no user found, create a new one
  !isUserFound && await axios.post(
    formatCreateUserURL(), {}, { headers: formatRequestHeader(null, authToken)}
    ).then(resp => {
        //console.log(resp);
    }).catch(error => {
      console.error('post user error');
      throw (error);
    });
}

export const setUser = user => ({
  type: SET_USER,
  user: user
});

export const setUserFirstname = firstname => ({
  type: SET_USER_FIRSTNAME,
  firstname: firstname
});

export const setUserPlan = plan => ({
  type: SET_USER_PLAN,
  plan: plan
});

export const setUserTaskLimit = tasklimit => ({
  type: SET_USER_TASK_LIMIT,
  tasklimit: tasklimit
});

export const setUserSpaceLimit = spacelimit => ({
  type: SET_USER_SPACE_LIMIT,
  spacelimit: spacelimit
});

export const setUserLastname = lastname => ({
  type: SET_USER_LASTNAME,
  lastname: lastname
});

export const setUserContactnumber = contactnumber => ({
  type: SET_USER_CONTACT_NUMBER,
  contactnumber: contactnumber
});

export const setUserAddress = address => ({
  type: SET_USER_ADDRESS,
  address: address
});

export const setUserRole = role => ({
  type: SET_USER_ROLE,
  role: role
});

export const setIsAuth = isAuth => ({
  type: SET_ISAUTH,
  isAuth: isAuth
});

export const setError = error => ({
  type: SET_ERROR,
  error: error
});

export const setAccesstoken = accessToken => ({
  type: SET_ACCESSTOKEN,
  accessToken: accessToken
});

export default function reducer(
  state = {
    isAuth: false,
    error: null,
    user: null,
    accessToken: null,
    firstname: '',
    lastname: '',
    role: '',
    contactnumber: '',
    address: '',
    plan: 'basic',
    spacelimit: 0,
    tasklimit: 0
  },
  action,
) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_USER_PLAN:
      return {
        ...state,
        plan: action.plan
      };
    case SET_USER_TASK_LIMIT:
      return {
        ...state,
        tasklimit: action.tasklimit
      };
    case SET_USER_SPACE_LIMIT:
      return {
        ...state,
        spacelimit: action.spacelimit
      };
    case SET_USER_FIRSTNAME:
      return {
        ...state,
        firstname: action.firstname
      };
    case SET_USER_LASTNAME:
      return {
        ...state,
        lastname: action.lastname
      };
    case SET_USER_CONTACT_NUMBER:
      return {
        ...state,
        contactnumber: action.contactnumber
      };
    case SET_USER_ADDRESS:
      return {
        ...state,
        address: action.address
      };
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.role
      };
    case SET_ISAUTH:
      return {
        ...state,
        isAuth: action.isAuth
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case SET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      };
    default:
      break;
  }
  return state;
}
