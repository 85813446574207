import React, { useState } from "react";
import { v4 } from "uuid";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Button, Grid, Paper } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

// styles
import useStyles from "./styles";

// API
import { formatUploadURL } from '../../utils/apis';
import { formatRequestHeader } from '../../utils/formatter';
import { API_KEY } from '../../utils/constants';

function UploadVideoPage(props) {
  var classes = useStyles();
  const { accessTokenState } = props;
  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = React.useState(false);


  const getUploadParams = async ({ file, meta: { name } }) => {
    //console.log('getUploadParams');
    //console.log(name);
    const uuidName = `${v4()}.mp4`;

    const signedUrl = await axios.get(
      formatUploadURL(uuidName),
    {
      headers: formatRequestHeader(API_KEY,accessTokenState)
    }).then(response => {
      // console.log('s3 get url back');
      // console.log(response);
      return response.data.signedUrl;
    }).catch(e => {
      console.error('something went wrong when uploading, try again', e);
      setRedirect(true);
    });

    const headerObj = {
      'x-amz-meta-auth': accessTokenState
    }
    return { method: 'put', body: file, url: signedUrl, headers: headerObj}
  }

  const handleChangeStatus = ({ meta }, status) => {
    //console.log('handleChangeStatus');
    //console.log(status, meta)
  }

  const handleSubmit = (files, allFiles) => {
    //console.log('handleSubmit');
    setOpen(true);
    //console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, please check back later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
    </Dialog>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid container>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <Widget title="Upload" disableWidgetMenu>
                <div className={classes.dashedBorder}>

                  <Typography className={classes.text} size="md">
                    Please upload *.mp4 video file.
                  </Typography>
                  <Typography className={classes.text} size="md">
                    We only support English content right now. System will extract audio file and transcribe it into txt.
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                accept="image/*,audio/*,video/*"
                inputContent={(files, extra) => (extra.reject ? 'Image, audio and video files only' : 'Drag Files Into This')}
                styles={{
                  dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                  inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </div>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(UploadVideoPage);