import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// components
import CaptionTable from "./components/CaptionTable/CaptionTable";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

// API
import {
  formatVideoURL,
  formatPutCaptionURL,
  formatVideoNameURL,
  formatVideoScriptionURL,
  formatGetCaptionsSRTURL,
  formatGetCaptionsVTTURL
} from '../../utils/apis';
import { formatRequestHeader } from '../../utils/formatter';
import { API_KEY } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

function VideoPage(props) {
  const { accessTokenState } = props;
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [isPageDataReady, setIsPageDataReady] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [captionList, setCaptionList] = useState([]);
  const [cloneCaptionList, setCloneCaptionList] = useState([]);

  const params = useParams();

  const handleInfoDialogOpen = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  //input string array, output, a string with line break
  //input
  //         0: {start: 0, caption: "they try to make you comfortable. They try to put you", wordConfidence: Array(11), end: 3.54}
        // 1: {start: 3.55, caption: "in a quite nice situation with stand the food that", wordConfidence: Array(10), end: 9.75}
        // 2: {start: 9.75, caption: "look like look look more like a dog food then food", wordConfidence: Array(11), end: 14.78}
        // 3: {start: 14.79, caption: "proper for wild animals. All right. If you try to compare", wordConfidence: Array(11), end: 19.68}

  //output
  // [
  //     {
	// 				start: 0.0,
	// 				end: 5.0,
	// 				caption: ''
	// 			}
  // ]
  const parseCaptionList = (preformatList) => {
    preformatList.forEach(element => {
      if(element.hasOwnProperty('wordConfidence')) {
        delete element.wordConfidence
      }
    });
    return preformatList;
  }

  const onTextFieldChange = (event) => {
     const arrayId = parseInt(`${event.target.id}`,10);
     const content = `${event.target.value}`;
     let temp = JSON.parse(JSON.stringify(cloneCaptionList));
     temp[arrayId]['caption'] = content;
     setCloneCaptionList(temp);
   };

   // Update captionList
   const onSaveAll = () => {
    // deep clone all cloneCaptionList and set captionList
    setCaptionList(JSON.parse(JSON.stringify(cloneCaptionList)));
   }

   // Submit current caption
   const onSubmit = () => {
    setIsPageLoading(true);

    const urlParamVideoId = params.id;
    axios.put(
      formatPutCaptionURL(urlParamVideoId),
      { 'captions':  captionList},
      {
        headers: formatRequestHeader(API_KEY, accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        setIsPageLoading(false);
        handleInfoDialogOpen();
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
    });

   }

   // Save changes to caption list
   const onSave = (event) => {
    const arrayId = parseInt(`${event.target.id}`,10);

    let temp = JSON.parse(JSON.stringify(captionList));

    temp[arrayId]['caption'] = `${cloneCaptionList[arrayId]['caption']}`;
    setCaptionList(temp);
   }


  const onVideoNameTextFieldChange = (event) =>{
    setVideoName(event.target.value.trim());
  }

  const saveVideoName = () => {
    setIsPageLoading(true);

    const urlParamVideoId = params.id;
    axios.post(
      formatVideoNameURL(urlParamVideoId),
      { 'name':  videoName},
      {
        headers: formatRequestHeader(API_KEY, accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        setIsPageLoading(false);
        handleInfoDialogOpen();
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
    });

  }

  const onVideoDescriptionTextFieldChange = (event) =>{
    setVideoDescription(event.target.value.trim());
  }

  const saveVideoDescription = () => {
    setIsPageLoading(true);

    const urlParamVideoId = params.id;
    axios.post(
      formatVideoScriptionURL(urlParamVideoId),
      { 'description':  videoDescription},
      {
        headers: formatRequestHeader(API_KEY, accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        setIsPageLoading(false);
        handleInfoDialogOpen();
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
    });
  }

  const onDownloadSRT = () => {
    setIsPageLoading(true);

    const urlParamVideoId = params.id;
    axios.get(
      formatGetCaptionsVTTURL(urlParamVideoId),
      {
        headers: formatRequestHeader(API_KEY, accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        setIsPageLoading(false);

        var blob = new Blob([resp.data], {type: "text/srt;charset=utf-8"});
        var csvURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${videoName}.srt`);
        tempLink.click();
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
    });
  }

  const onDownloadWebvtt = () => {
    setIsPageLoading(true);

    const urlParamVideoId = params.id;
    axios.get(
      formatGetCaptionsSRTURL(urlParamVideoId),
      {
        headers: formatRequestHeader(API_KEY, accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        setIsPageLoading(false);

        var blob = new Blob([resp.data], {type: "text/vtt;charset=utf-8"});
        var csvURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${videoName}.vtt`);
        tempLink.click();
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
    });
  }

  useEffect(() => {
    const urlParamVideoId = params.id;
    accessTokenState && axios.get(
      formatVideoURL(urlParamVideoId),
      {
        headers: formatRequestHeader(API_KEY,accessTokenState)
      }).then(resp => {
        // console.log('have back resp');
        // console.log(resp);
        if(!resp['data'] || !resp['data']['video'] ) {
          setIsPageDataReady(false);
        } else {
          setIsPageDataReady(true);

          //console.log(resp['data']['video']);
          setVideoName(resp['data']['video']['name']);
          if(resp['data']['video']['description']) {
            setVideoDescription(resp['data']['video']['description']);
          }
          //resp['data']['video']['captions'] is an array
          //         0: {start: 0, caption: "they try to make you comfortable. They try to put you", wordConfidence: Array(11), end: 3.54}
          // 1: {start: 3.55, caption: "in a quite nice situation with stand the food that", wordConfidence: Array(10), end: 9.75}
          // 2: {start: 9.75, caption: "look like look look more like a dog food then food", wordConfidence: Array(11), end: 14.78}
          // 3: {start: 14.79, caption: "proper for wild animals. All right. If you try to compare", wordConfidence: Array(11), end: 19.68}
          setCaptionList(parseCaptionList(JSON.parse(resp['data']['video']['captions'])));
          setCloneCaptionList(parseCaptionList(JSON.parse(resp['data']['video']['captions'])));
        }
        setIsPageLoading(false);
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
      setRedirect(true);
    });
  },[accessTokenState]);


  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Dialog
        open={infoDialogOpen}
        onClose={handleInfoDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, please check back later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
    </Dialog>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        {
        !isPageDataReady ?
        <Grid container>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={10} md={6}>
              <Box m={5}>
                <Widget disableWidgetMenu>
                  <div className={classes.dashedBorder}>
                    <Typography className={classes.text} size="xxl">
                      Data is not ready, please check back later
                    </Typography>
                  </div>
                </Widget>
              </Box>
            </Grid>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid> :
        <>
          <Grid container>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={10} md={6}>
              <Box m={5}>
                <Widget disableWidgetMenu>
                  <div className={classes.dashedBorder}>
                    <Typography className={classes.text} size="md">
                      On this page you can{"\n"}
                      1. Update video name and description.{"\n"}
                      2. Update captions based on their time, after you are done, click on Submit Captions.{"\n"}
                      3. Download captions in webvtt or srt format.{"\n"}
                    </Typography>
                  </div>
                </Widget>
              </Box>
            </Grid>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={7} md={4}>
              <Box m={2}>
                <TextField
                  id="video-name"
                  label="Video Name"
                  variant="outlined"
                  onChange={onVideoNameTextFieldChange}
                  defaultValue={videoName}
                />
              </Box>
            </Grid>

            <Grid item xs={2} md={2}>
              <Box m={2}>
                <Button
                fullWidth={true}
                size='large'
                color="secondary"
                variant="outlined"
                onClick={saveVideoName}
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={7} md={4}>
              <Box m={2}>
                <TextField
                  id="video-description"
                  label="Video Description"
                  variant="outlined"
                  onChange={onVideoDescriptionTextFieldChange}
                  defaultValue={videoDescription}
                />
              </Box>
            </Grid>

            <Grid item xs={2} md={2}>
              <Box m={2}>
                <Button
                fullWidth={true}
                size='large'
                color="secondary"
                variant="outlined"
                onClick={saveVideoDescription}
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={5} md={3}>
              <Box m={2}>
                <Button
                fullWidth={true}
                size='large'
                color="secondary"
                variant="contained"
                onClick={onDownloadSRT}
                >
                  Download SRT Captions
                </Button>
              </Box>
            </Grid>

            <Grid item xs={5} md={3}>
              <Box m={2}>
                <Button
                fullWidth={true}
                size='large'
                color="secondary"
                variant="contained"
                onClick={onDownloadWebvtt}
                >
                  Download Webvtt Captions
                </Button>
              </Box>
            </Grid>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={10} md={6}>
              <Box m={5}>
                <Widget title="Captions" upperTitle noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
                  <CaptionTable
                    data={captionList}
                    onTextFieldChange={onTextFieldChange}
                    onSave={onSave}/>
                </Widget>
              </Box>
            </Grid>
            <Grid item xs={1} md={3}>
              <Paper></Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={1} md={4}>
              <Paper></Paper>
            </Grid>
            <Grid item xs={5} md={2}>
              <Box m={2}>
                <Button
                fullWidth={true}
                size='large'
                color="secondary"
                variant="contained"
                onClick={onSaveAll}
                >
                    Save All Captions
                </Button>
              </Box>
            </Grid>
            <Grid item xs={5} md={2}>
              <Box m={2}>
                <Button
                fullWidth={true}
                size='large'
                color="secondary"
                variant="contained"
                onClick={onSubmit}
                >
                    Submit Captions
                </Button>
              </Box>
            </Grid>
            <Grid item xs={1} md={4}>
              <Paper></Paper>
            </Grid>
          </Grid>
        </>
        }

      </Grid>
    </Paper>
    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(VideoPage);