// Sidebar
export const TOGGLE_SIDEBAR = 'THEME_OPTIONS/TOGGLE_SIDEBAR';
export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export default function reducer(
  state = {
    // Sidebar
    isSidebarOpened: true
  },
  action,
) {
  switch (action.type) {
    // Sidebar
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpened: !state.isSidebarOpened,
      };
    default:
      break;
  }
  return state;
}
