import {
  AUTH0_CLIENT_ID,
  RESET_PS_URI
} from './constants';

export const calSpaceUsage = (itemArray) => {
  let contentLength = 0;
  // 1024*1024 MB
  itemArray.forEach(element => {
    contentLength += parseInt(element['contentLength'], 10);
  })
  return contentLength/(1024*1024);
}

export const formatChangePsRequestOption = (email) => {
  return {
    method: 'POST',
    url: RESET_PS_URI,
    headers: {'content-type': 'application/json'},
    data: {
      client_id: AUTH0_CLIENT_ID,
      email: email,
      connection: 'Username-Password-Authentication'
    }
  };
}

export const formatRequestHeader = (APIKey=null, authToken=null) => {
  return {
  'Cache-Control' : 'no-cache',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'X-Api-Key': APIKey ? APIKey :'*',
  'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
  Authorization: `Bearer ${authToken}`,
  }
};

export const extractSArray = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output
  //[{
  // id: "1a787d15-073c-4d1b-a31f-db744bf32b56"
  // status: "PROCESSING"
  // text: "This is working!"
  // userId: "6077e3b1eda109006996e3c0"
  // voice: "Joanna"
  //}]
  if(!Array.isArray(array)) {
    return ;
  }
  const newArray = array.map(item => {
    let compactItem = {};
    //console.log(item);
    Object.keys(item).forEach(function(key) {
      let valueObj = item[key];
      if(valueObj['S']) {
        compactItem[key] = valueObj['S'];
      } else if(valueObj['BOOL']) {
        compactItem[key] = valueObj['BOOL'];
      } else if(valueObj['N']) {
        compactItem[key] = valueObj['N'];
      }
    });
    return compactItem;
  })

  return newArray;
}

export const formatLastWeekData = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output
  //{
  // TotalCreated: 20
  // TotalSucceed: 20
  // TotalFailed: 20
  // SuccessRate: 100%
  //}
  if(!Array.isArray(array)) {
    return {
      TotalCreated: 0,
      TotalSucceed: 0,
      TotalFailed: 0,
      SuccessRate: '100%'
    };
  }

  let totalCreated = 0;
  let totalSucceed = 0;
  let totalFailed = 0;
  let successRate = '100%'


  array.forEach(function (item, index) {
    const createdAt = item['processedDate'];
    const createdAtInMill = new Date(createdAt).getTime();
    const now = new Date().getTime();
    const WEEK_IN_MILL = 604800000;

    // Within one week
    if(now - createdAtInMill < WEEK_IN_MILL) {
      totalCreated++;
      if(item['status'] === 'READY') {
        totalSucceed++;
      }
    }
  });

  if(totalCreated !== 0) {
    successRate = (totalSucceed/totalCreated).toFixed(1)*100;
  }


  totalFailed = totalCreated - totalSucceed;
  return  {
      TotalCreated: totalCreated,
      TotalSucceed: totalSucceed,
      TotalFailed: totalFailed,
      SuccessRate: successRate
    };
}

export const formatLastMonthData = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output
  //{
  // TotalCreated: 20
  // TotalSucceed: 20
  // TotalFailed: 20
  // SuccessRate: 100%
  //}
  if(!Array.isArray(array)) {
    return {
      TotalCreated: 0,
      TotalSucceed: 0,
      TotalFailed: 0,
      SuccessRate: '100%'
    };
  }

  let totalCreated = 0;
  let totalSucceed = 0;
  let totalFailed = 0;
  let successRate = '100%'


  const now = new Date().getTime();
  const MONTH_IN_MILL = 604800000 * 4;

  array.forEach(function (item, index) {
    const createdAt = item['processedDate'];
    const createdAtInMill = new Date(createdAt).getTime();

    // Within one month
    if(now - createdAtInMill < MONTH_IN_MILL) {
      totalCreated++;
      if(item['status'] === 'READY') {
        totalSucceed++;
      }
    }
  });

  if(totalCreated !== 0) {
    successRate = (totalSucceed/totalCreated).toFixed(1)*100;
  }


  totalFailed = totalCreated - totalSucceed;
  return  {
      TotalCreated: totalCreated,
      TotalSucceed: totalSucceed,
      TotalFailed: totalFailed,
      SuccessRate: successRate
    };
}

export const formatOverallData = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output
  //{
  // TotalCreated: 20
  // TotalSucceed: 20
  // TotalFailed: 20
  //}
  if(!Array.isArray(array)) {
    return {
      TotalCreated: 0,
      TotalSucceed: 0,
      TotalFailed: 0,
    };
  }

  let totalCreated = 0;
  let totalSucceed = 0;
  let totalFailed = 0;


  array.forEach(function (item, index) {
    totalCreated++;
    if(item['status'] === 'READY') {
      totalSucceed++;
    }
  });


  totalFailed = totalCreated - totalSucceed;
  return  {
      TotalCreated: totalCreated,
      TotalSucceed: totalSucceed,
      TotalFailed: totalFailed
    };
}


export const formatTotalCreatedDataByMonth = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output, length = 6, same as chart X-axis
  // 109 is the latest data
  // [31, 40, 28, 51, 42, 109]
  if(!Array.isArray(array)) {
    return [0, 0, 0, 0, 0, 0];
  }
  const now = new Date().getTime();
  const MONTH_IN_MILL = 604800000 * 4;
  let data = [];
  let totalCreated = 0;

  //count = 0 means most recent
  for(let count = 0; count < 6; count++) {
    let left = MONTH_IN_MILL * count;
    let right = MONTH_IN_MILL * (count+1);
    let totalCreated = 0;
    for(let index = 0; index<array.length; index++){
      const item = array[index];
      const createdAtInMill = new Date(item['processedDate']).getTime();
      // Within interval
      if(now - createdAtInMill > left && now - createdAtInMill < right) {
        totalCreated++;
      }
    }
    data.push(totalCreated);
  }
  data.push(totalCreated);
  // console.log('before');
  // console.log(data);
  const current_length = data.length;
  const OUTPUT_LENGTH = 6;
  // data = [3,4,0]  3 means most recent
  if(current_length < OUTPUT_LENGTH) {
    for(let i = current_length; i<OUTPUT_LENGTH; i++ ){
     data.push(0);
    }
  } else {
    data = data.slice(0, OUTPUT_LENGTH);
  }

  // console.log('after');
  // console.log(data);
  return data.reverse();
}

export const formatTotalSucceedDataByMonth = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output, length = 6, same as chart X-axis
  // 109 is the latest data
  // [31, 40, 28, 51, 42, 109]
  if(!Array.isArray(array)) {
    return [0, 0, 0, 0, 0, 0];
  }

  const now = new Date().getTime();
  const MONTH_IN_MILL = 604800000 * 4;
  let data = [];
  let totalSucceed = 0;

  //count = 0 means most recent
  for(let count = 0; count < 6; count++) {
    let left = MONTH_IN_MILL * count;
    let right = MONTH_IN_MILL * (count+1);
    let totalSucceed = 0;
    for(let index = 0; index<array.length; index++){
      const item = array[index];
      const createdAtInMill = new Date(item['processedDate']).getTime();
      // Within interval
      if(now - createdAtInMill > left && now - createdAtInMill < right) {
        if(item['status'] === 'READY') {
          totalSucceed++;
        }
      }
    }
    data.push(totalSucceed);
  }
  data.push(totalSucceed);
  // console.log('before');
  // console.log(data);
  const current_length = data.length;
  const OUTPUT_LENGTH = 6;
  // data = [3,4,0]  3 means most recent
  if(current_length < OUTPUT_LENGTH) {
    for(let i = current_length; i<OUTPUT_LENGTH; i++ ){
     data.push(0);
    }
  } else {
    data = data.slice(0, OUTPUT_LENGTH);
  }

  // console.log('after');
  // console.log(data);
  return data.reverse();
}

export const formatTotalErrorDataByMonth = (array) => {
  //Input
  //[{
  // id: {S: "1a787d15-073c-4d1b-a31f-db744bf32b56"}
  // status: {S: "PROCESSING"}
  // text: {S: "This is working!"}
  // userId: {S: "6077e3b1eda109006996e3c0"}
  // voice: {S: "Joanna"}
  //}]

  //Output, length = 6, same as chart X-axis
  // 109 is the latest data
  // [31, 40, 28, 51, 42, 109]
  if(!Array.isArray(array)) {
    return [0, 0, 0, 0, 0, 0];
  }

  const now = new Date().getTime();
  const MONTH_IN_MILL = 604800000 * 4;
  let data = [];
  let totalError = 0;

  //count = 0 means most recent
  for(let count = 0; count < 6; count++) {
    let left = MONTH_IN_MILL * count;
    let right = MONTH_IN_MILL * (count+1);
    let totalError = 0;
    for(let index = 0; index<array.length; index++){
      const item = array[index];
      const createdAtInMill = new Date(item['processedDate']).getTime();
      // Within interval
      if(now - createdAtInMill > left && now - createdAtInMill < right) {
        if(item['status'] !== 'READY') {
          totalError++;
        }
      }
    }
    data.push(totalError);
  }
  data.push(totalError);
  // console.log('before');
  // console.log(data);
  const current_length = data.length;
  const OUTPUT_LENGTH = 6;
  // data = [3,4,0]  3 means most recent
  if(current_length < OUTPUT_LENGTH) {
    for(let i = current_length; i<OUTPUT_LENGTH; i++ ){
     data.push(0);
    }
  } else {
    data = data.slice(0, OUTPUT_LENGTH);
  }

  // console.log('after');
  // console.log(data);
  return data.reverse();
}