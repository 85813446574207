import React from "react";
import { Link } from 'react-router-dom';
import {
  Button,
  IconButton,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@material-ui/core";
import useStyles from "./styles";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';

const states = {
  ready: "success",
  processing: "warning",
  errored: "secondary",
  complete: "info"
};

const KEY_ARRAY= ['Name','ProcessedDate', 'Description', 'Status', 'StatusText', 'Star', 'Details'];

export default function TableComponent({ onStarClick, data }) {

  const classes = useStyles();
  if(!Array.isArray(data) || !data.length || !data[0]) {
    return (null);
  }
  return (
    <TableContainer>
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {KEY_ARRAY.map(key => (
            <TableCell style={{
            }} key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ videoId, processedDate, name, description, status, statusText, isStarred }) => (
          <TableRow key={videoId}>
            <TableCell>{name}</TableCell>
            <TableCell>{processedDate}</TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>
              <Chip label={status} classes={{root: classes[states[status.toLowerCase()]]}}/>
            </TableCell>
            <TableCell>{statusText}</TableCell>
            <TableCell>{
              isStarred ?
                <IconButton color="primary" aria-label="starred" onClick={() =>onStarClick(videoId, !isStarred)}>
                  <StarRateRoundedIcon />
                </IconButton>
               :
               <IconButton color="primary" aria-label="notstarred" onClick={() =>onStarClick(videoId, !isStarred)}>
                <StarBorderRoundedIcon />
              </IconButton>
              }
            </TableCell>
            <TableCell>
              <Button
                component={Link}
                to={`/videos/${videoId}`}
                variant={"contained"}
                color="primary">
                  Details
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
}
