//DOMAIN
export const LOCAL_HOST = "http://localhost:3000/"; // Used for dev
export const DOMAIN = "https://www.accelai.co/" // Use for marketing page, log out redirect
export const SUB_DOMAIN = "https://app.accelai.co/" // Use for real app, log in redirect

// Auth0
export const AUTH0_CLIENT_ID = '693ha0a9l45udSO4TOIQtz6uCh9J0BOg';
export const AUTH0_CLIENT_SECRET = 'wciYjYc0o5A1ib0W_HJwvWFKPq3WnjuoiwWv2GM9Nn8nUzEnwRrBlfO07okBPf6B';
export const AUTH0_DOMAIN = 'dev-x-w4j-5h.us.auth0.com';
export const AUTH0_AUDIENCE = `https://your-api-gateway/`;
export const AUTH0_LOGIN_REDIRECT_URI = `${SUB_DOMAIN}`; // this needs to match auth0.com
export const AUTH0_LOGOUT_REDIRECT_URI = `${DOMAIN}`; // this needs to match auth0.com
export const RESET_PS_URI = `https://${AUTH0_DOMAIN}/dbconnections/change_password`;

// API
export const API_KEY = '64250310236916027751Aa';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// Routes
export const INTRO = '/';
export const DASHBOARD = '/dashboard';
export const VIDEOS = '/videos';
export const UPLOAD = '/upload';
export const VOCABULARY = '/vocabulary';
export const TWEAK = '/tweak';
export const VIDEO = '/videos/:id';
export const USAGE = '/usage';
export const RECENT = '/recent';
export const STARRED = '/starred';
export const UPGRADE = '/upgrade';
export const SUPPORT = '/support';
export const PROFILE = '/profile';
export const ERROR_500 = '/error500';
export const CATCH_ALL = '/*';
