import React, { useState, useEffect } from "react";
import { Circle } from 'rc-progress';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Container
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
// components
import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
// API
import { formatVideosURL } from '../../utils/apis';
// Route
import {
  UPGRADE,
} from '../../utils/constants';
// Formatter
import {
  formatRequestHeader,
  calSpaceUsage
} from '../../utils/formatter';

function UsagePage(props) {
  const {
    accessTokenState,
    tasklimit,
    spacelimit,
    plan
  } = props;
  var theme = useTheme();
  const [taskPercentage, setTaskPercentage] = useState(1);
  const [spacePercentage, setSpacePercentage] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(true);


  useEffect(() => {
    accessTokenState && axios.get(formatVideosURL(), {
      headers: formatRequestHeader(null,  accessTokenState)
    }).then(resp => {
      const spaceUsage = calSpaceUsage(resp['data']['videos']);
      const taskCount = resp['data']['videos'].length;
      let taskPer = (taskCount/tasklimit).toFixed(2)*100;
      const spacePer = (spaceUsage/spacelimit).toFixed(2)*100;
      if(plan === 'pro') {
        taskPer = 1;
      }
      setTaskPercentage(taskPer);
      setSpacePercentage(spacePer);
      setIsPageLoading(false);
    }).catch(error => {
        console.error(error);
      setIsPageLoading(false);

    });
  },[accessTokenState, spacelimit, tasklimit, plan]);

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={4}>
          <Grid container spacing={4}>
            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={10} md={6}>
                <Box m={4}>
                  <Widget title="Instruction" disableWidgetMenu>
                    <div >
                      <Typography variant="h6">
                        Current Plan Usage
                      </Typography>
                    </div>
                  </Widget>
                </Box>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper />
              </Grid>
              <Grid item xs={5} md={3}>
                <Box m={4}>
                  <Typography variant="h3">
                  Space Usage {spacePercentage}%
                </Typography>
                </Box>
              </Grid>
              <Grid item xs={5} md={3}>
                <Box m={4}>
                  <Typography variant="h3">
                    Duration Usage {taskPercentage}%
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper/>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={5} md={3}>
                <Container maxWidth="sm">
                  <Circle percent={spacePercentage} strokeWidth="4" strokeColor={theme.palette.success.main} />
                </Container>
              </Grid>
              <Grid item xs={5} md={3}>
                <Container maxWidth="sm">
                  <Circle percent={taskPercentage} strokeWidth="4" strokeColor={theme.palette.warning.main} />
                </Container>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item xs={3} md={5}>
                <Paper />
              </Grid>
              <Grid item xs={6} md={4}>
                <Box mt={4}>
                  <Button
                    disabled={plan === 'pro'}
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={UPGRADE}
                  >
                    Request Upgrade Plan
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} md={2}>
                <Paper />
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    </Paper>
    </>
  );
}

const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
  tasklimit: state.AuthOptions.tasklimit,
  spacelimit: state.AuthOptions.spacelimit,
  plan: state.AuthOptions.plan,
});

export default connect(mapStateToProps)(UsagePage);